<template>
    <div class="notification" v-if="this.data">
        <h1 class="text-secondary font">Obvestila</h1>
        <h3 class="mt-2 font">{{this.data.message.subject}}</h3>
        <div v-if="pdf && pdf.length > 0">
            <div v-for="(item,id) of pdf" :key="'pdf_' + id" style="max-height: 70vh; overflow-y: scroll;" class="my-2">
                <pdf 
                    :src="item.src"
                    v-for="i in item.pages"
                    :key="i"
                    :page="i"
                    style="height: 100%"
                ></pdf>
            </div>
        </div>
        
        <b-row class="mt-4 d-flex justify-content-center" align-v="center">
            <b-col cols="12" sm="6" class="d-flex justify-content-center justify-content-sm-start">
                <b-button squared variant="primary" @click="downloadPdfs()">
                    <h2 v-if="pdf && pdf.length == 1" class="mb-0 text-white">Prenesi dokument <fa class="ml-1" icon="download"/></h2>
                    <h2 v-if="pdf && pdf.length > 1" class="mb-0 text-white">Prenesi dokument <fa icon="download"/></h2>
                </b-button>
            </b-col>
            <b-col cols="12" sm="6" class="d-flex justify-content-center justify-content-sm-end mt-1 mt-sm-0">
                <b-button squared variant="primary" @click="$router.push({name: 'electronic-document-signing'})">
                    <h2 class="mb-0 text-white">Elektronsko glasovanje <fa icon="arrow-right"/></h2>
                </b-button>
            </b-col>
        </b-row>
        <div class="mt-4">
            <h1 class="text-secondary font">Prenesi in oddaj dokument</h1>
            <h3 class="mt-1">Prosimo vas, da
                <b-link>prenesete dokument</b-link>, izpolnite, podpišete in skeniranega oddate preko spodnjega
                obrazca. V kolikor imate digitalno potrdilo, lahko glasujete tudi elektronsko
                <b-link @click="$router.push({name: 'electronic-document-signing'})">na tej povezavi</b-link>.</h3>
        </div>
        <b-row class="ml-0 mt-2">
            <b-col cols="12" md="12" lg="6" class="left-col p-1 p-lg-2 h-100">
                <validation-observer ref="validate">
                    <b-form class="form">
                        <b-form-group>
                            <validation-provider #default="{ errors }" name="ime in priimek" rules="required|email">
                                <b-form-input id="name" v-model="contact.name" placeholder="IME IN PRIIMEK"/>
                                <small class="text-primary">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                                <b-form-input id="email" v-model="contact.email" placeholder="ELEKTRONSKO NASLOV"/>
                                <small class="text-primary">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <validation-provider #default="{ errors }" name="sporočilo" rules="required">
                                <b-form-textarea id="text" v-model="contact.text" rows="8" max-rows="8" placeholder="SPOROČILO"/>
                                <small class="text-primary">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </b-col>
            <b-col cols="12" md="12" lg="6" class="px-0 mt-1 px-lg-1 mt-lg-0">
                <b-card class="h-100">
                    <div class="right-col h-100">
                        <div class="p-2">
                            <a>
                                <h2 class="d-flex align-items-center">
                                    <fa class="text-secondary" icon="plus-square" size="2x"/>
                                    <span class="text-default ml-1">Naloži dokument</span>
                                </h2>
                            </a>
                            <div class="mt-1">
                                <h2>Dokument.pdf</h2>
                                <h2>Dokument.pdf</h2>
                            </div>
                        </div>
                    </div>

                    <template #footer class="m-0 p-0">
                        <b-button squared block class="mt-1 font">ODDAJ DOKUMENT</b-button>
                    </template>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'
    import { BRow, BCol, BButton, BLink, BForm, BFormGroup, BFormInput, BFormTextarea, BCard } from 'bootstrap-vue'
    import pdf from 'vue-pdf'
    import {FileMimeType} from '@/libs/mimeTypes'

    export default {
        components: {
            pdf,
            ValidationProvider,
            ValidationObserver,
            BRow,
            BCol,
            BButton,
            BLink,
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BCard
        },
        data() {
            return {
                currentPage: 0,
                pageCount: 0,
                pdf: [],
                notificationId: null,
                contact: {
                    name: '',
                    email: '',
                    text: ''
                },
                required,
                email,
                data: null
            }
        },
        methods: {
            downloadPdfs() {
                this.$http({
                    url: `/api/iiportal-user/v1/e_manager/obvestila/attachements/${ this.notificationId}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                }).then((response) => {
                    let fileType = 'zip'
                    fileType = FileMimeType[response.data.type]

                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Obvestilo.${fileType}`) //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })
            },
            async loadPdfs() {
                this.pdf = []

                for (const item of this.data.message.attachments) {
                    const single_pdf = await this.$http.get(`/api/iiportal-user/v1/attachements/${ item}`, {responseType: 'blob'})

                    const blob = new Blob([single_pdf.data])
                    const objectUrl = URL.createObjectURL(blob)
                    const pdf_src = await pdf.createLoadingTask(objectUrl)
                    pdf_src.promise.then((pdf) => {
                        const numPages = pdf.numPages
                        this.pdf.push({ src: pdf_src, pages: numPages, objectUrl })
                    })
                }

            },
            setNotificationAsMarked() {

            },
            async loadData() {
                try {
                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/obvestila/${ this.notificationId}`)
                    this.data = res.data
                    await this.loadPdfs()
                } catch (err) {
                    this.$printError(err.message)
                }
               
            }
        },
        mounted() {
            this.loadData()
        },
        created() {
            this.notificationId = this.$route.params.notification_id
        }

    }
</script>

<style>
.notification .font {
  font-weight: bold;
}
.notification div a {
  color: #009FD4;
}
.notification .left-col {
  background-image: linear-gradient(to right, #189DD4, #189DD4);
  color: white !important;
}
.notification .right-col {
  background-color: #FAFBFB;
}
.notification .card {
  box-shadow: none;
}
.notification .card .card-body {
  padding: 0;
}
.notification .form input, .form textarea{
  background-color: transparent;
  color: white !important;
}
.notification .form-control, .notification .custom-select {
  border-radius: 0;
  border: 1px solid white;
}
.notification .form input::placeholder, .form textarea::placeholder {
  color: white;
}
</style>
